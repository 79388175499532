import Image from 'next/image';
import React from 'react';
import Scroll from '@/components/scroll';
import SectionsEnum from '@/enums/SectionsEnum';
import useLocale from '@/hooks/useLocale';
import useStore from '@/store/index';
import {scroll} from '@/utils/config';
import dataImages from './content.dataImages';
import styles from './content.module.css';

const Content: React.FC = () => {
    const locale = useLocale();
    const setOrder = useStore(state => state.order.set);
    const handleClick = (value: SectionsEnum) => setOrder({value});

    return (
        <>
            <article
                id={SectionsEnum.about}
                className={`${styles.article} ${styles.left}`}
                data-testid="component-content"
            >
                <picture className={styles.image}>
                    <Image
                        src="/images/image_01.jpg"
                        width={994}
                        height={552}
                        quality={100}
                        placeholder="blur"
                        blurDataURL={dataImages.image01}
                        alt={locale.sections[SectionsEnum.about].title}
                    />
                </picture>
                <div className={styles.text}>
                    <h2 className={styles.heading}>{locale.sections[SectionsEnum.about].title}</h2>
                    <p className={styles.paragraph}>{locale.sections[SectionsEnum.about].content}</p>
                    <div className={styles.footer}>
                        <Scroll
                            {...scroll}
                            asButton
                            to={SectionsEnum.contact}
                            offset={-100}
                            className={styles.button}
                        >
                            {locale.sections[SectionsEnum.about].button}
                        </Scroll>
                        <Image
                            src="/images/icon_epal.png"
                            width={192}
                            height={56}
                            quality={100}
                            alt="EPAL &amp; EUR"
                        />
                    </div>
                </div>
            </article>
            <article
                id={SectionsEnum.sale}
                className={`${styles.article} ${styles.right}`}
            >
                <div className={styles.text}>
                    <h2 className={styles.heading}>{locale.sections[SectionsEnum.sale].title}</h2>
                    <p className={styles.paragraph}>{locale.sections[SectionsEnum.sale].content}</p>
                    <Scroll
                        {...scroll}
                        asButton
                        to={SectionsEnum.form}
                        offset={-100}
                        className={styles.button}
                        onClick={() => handleClick(SectionsEnum.sale)}
                    >
                        {locale.sections[SectionsEnum.sale].button}
                    </Scroll>
                </div>
                <picture className={styles.image}>
                    <Image
                        src="/images/image_02.jpg"
                        width={994}
                        height={552}
                        quality={100}
                        placeholder="blur"
                        blurDataURL={dataImages.image02}
                        alt={locale.sections[SectionsEnum.sale].title}
                    />
                </picture>
            </article>
            <article
                id={SectionsEnum.production}
                className={`${styles.article} ${styles.left}`}
            >
                <picture className={styles.image}>
                    <Image
                        src="/images/image_03.jpg"
                        width={1006}
                        height={552}
                        quality={100}
                        placeholder="blur"
                        blurDataURL={dataImages.image03}
                        alt={locale.sections[SectionsEnum.production].title}
                    />
                </picture>
                <div className={styles.text}>
                    <h2 className={styles.heading}>{locale.sections[SectionsEnum.production].title}</h2>
                    <p className={styles.paragraph}>{locale.sections[SectionsEnum.production].content}</p>
                    <Scroll
                        {...scroll}
                        asButton
                        to={SectionsEnum.form}
                        offset={-100}
                        className={styles.button}
                        onClick={() => handleClick(SectionsEnum.production)}
                    >
                        {locale.sections[SectionsEnum.production].button}
                    </Scroll>
                </div>
            </article>
            <article
                id={SectionsEnum.repair}
                className={`${styles.article} ${styles.right}`}
            >
                <div className={styles.text}>
                    <h2 className={styles.heading}>{locale.sections[SectionsEnum.repair].title}</h2>
                    <p className={styles.paragraph}>{locale.sections[SectionsEnum.repair].content}</p>
                    <Scroll
                        {...scroll}
                        asButton
                        to={SectionsEnum.form}
                        offset={-100}
                        className={styles.button}
                        onClick={() => handleClick(SectionsEnum.repair)}
                    >
                        {locale.sections[SectionsEnum.repair].button}
                    </Scroll>
                </div>
                <picture className={styles.image}>
                    <Image
                        src="/images/image_04.jpg"
                        width={1006}
                        height={552}
                        quality={100}
                        placeholder="blur"
                        blurDataURL={dataImages.image04}
                        alt={locale.sections[SectionsEnum.repair].title}
                    />
                </picture>
            </article>
            <article
                id={SectionsEnum.transport}
                className={`${styles.article} ${styles.left}`}
            >
                <picture className={styles.image}>
                    <Image
                        src="/images/image_05.jpg"
                        width={1006}
                        height={552}
                        quality={100}
                        placeholder="blur"
                        blurDataURL={dataImages.image05}
                        alt={locale.sections[SectionsEnum.transport].title}
                    />
                </picture>
                <div className={styles.text}>
                    <h2 className={styles.heading}>{locale.sections[SectionsEnum.transport].title}</h2>
                    <p className={styles.paragraph}>{locale.sections[SectionsEnum.transport].content}</p>
                </div>
            </article>
        </>
    );
};

export default Content;
